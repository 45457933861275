import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import { appDyslexia, appCartogram } from "./app-strucutred-data";

function Seo({
  description,
  lang,
  meta,
  title,
  image,
  isArticle,
  author,
  date,
  slug,
}) {
  const { site } = useStaticQuery(query);

  const metaDescription = description || site.siteMetadata.description;
  const metaTitle = title ? `${title} | Round Tower` : site.siteMetadata.title;
  const metaImage = image
    ? site.siteMetadata.siteUrl + image
    : site.siteMetadata.logo;

  // Initial breadcrumb list
  const itemListElement = [
    {
      "@type": "ListItem",
      item: {
        "@id": site.siteMetadata.siteUrl,
        name: "Homepage",
      },
      position: 1,
    },
  ];

  let structuredData = null;

  let url = `${site.siteMetadata.siteUrl}`;

  if (isArticle) {
    url = `${site.siteMetadata.siteUrl}${slug}`;

    structuredData = {
      "@context": "http://schema.org",
      "@type": "Article",
      author: {
        "@type": "Person",
        name: author,
      },
      copyrightHolder: {
        "@type": "Person",
        name: author,
      },
      copyrightYear: new Date().getFullYear(),
      creator: {
        "@type": "Person",
        name: author,
      },
      publisher: {
        "@type": "Organization",
        name: "Round Tower",
        logo: {
          "@type": "ImageObject",
          url: site.siteMetadata.logo,
          width: 650,
          height: 650,
        },
      },
      datePublished: date,
      dateModified: site.buildTime,
      description: metaDescription,
      headline: title,
      url: url,
      name: title,
      image: {
        "@type": "ImageObject",
        url: metaImage,
        width: 650,
        height: 650,
      },
      mainEntityOfPage: url,
    };

    // Push current blogpost into breadcrumb list
    itemListElement.push({
      "@type": "ListItem",
      item: {
        "@id": url,
        name: title,
      },
      position: 2,
    });
  } else {
    structuredData = {
      "@context": "http://schema.org",
      "@type": "Organization",
      "@id": site.siteMetadata.siteUrl + "/#organisation",
      serviceType: "Mobile App and Web Development",
      name: metaTitle,
      legalName: "Round Tower Software Studios Limited",
      image: {
        "@type": "ImageObject",
        url: metaImage,
        width: 650,
        height: 650,
      },
      logo: {
        "@type": "ImageObject",
        url: metaImage,
        width: 650,
        height: 650,
      },
      description: metaDescription,
      url: site.siteMetadata.siteUrl,
      foundingDate: "2018",
      foundingLocation: "Ireland",
      founders: [
        {
          "@type": "Person",
          name: "Kevin Murphy",
        },
      ],
      contactPoint: [
        {
          "@type": "ContactPoint",
          email: site.siteMetadata.email,
          telephone: site.siteMetadata.telephone,
          contactType: "customer service",
        },
      ],
      address: {
        "@type": "PostalAddress",
        addressLocality: "Main Street, Ardmore",
        addressRegion: "Co. Waterford",
        addressCountry: "Ireland",
        postalCode: "P36 AY24",
      },
      sameAs: [
        site.siteMetadata.facebook,
        site.siteMetadata.youtube,
        site.siteMetadata.linkedin,
        site.siteMetadata.instagram,
        site.siteMetadata.twitter,
      ],
    };
  }

  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    description: "Breadcrumbs list",
    name: "Breadcrumbs",
    itemListElement,
  };

  return (
    <>
      <title>{metaTitle}</title>
      <meta charSet="utf-8" />
      <meta
        name="viewport"
        content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
      />
      <meta name="description" content={metaDescription} />
      <meta name="og:description" content={metaDescription} />
      <meta name="og:image" content={metaImage} />
      <meta name="og:type" content="website" />
      <meta name="og:site_name" content={site.siteMetadata.title} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:image" content={metaImage} />
      <meta name="twitter:creator" content={site.siteMetadata.author} />
      <meta name="twitter:site" content={site.siteMetadata.twitter} />
      <meta
        name="keywords"
        content="Round Tower Software Studios, Mobile App Development, Web Development, Ardmore, Co. Waterford, Munster, Ireland,  Dyslexia, dyslexia.ai, Cartogram, Android, iOS"
      />

      <script className={"structured-data"} type="application/ld+json">
        {JSON.stringify(structuredData)}
      </script>
      <script className={"structured-data"} type="application/ld+json">
        {JSON.stringify(breadcrumb)}
      </script>
      <script className={"structured-data"} type="application/ld+json">
        {JSON.stringify(appCartogram(url))}
      </script>
      <script className={"structured-data"} type="application/ld+json">
        {JSON.stringify(appDyslexia(url))}
      </script>

      <link rel="canonical" href={url} />
    </>
  );
}

Seo.defaultProps = {
  description: ``,
  lang: `en`,
  meta: [],
  title: ``,
};

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  title: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
};

const query = graphql`
  query SiteDataQuery {
    site {
      buildTime(formatString: "YYYY-MM-DD")
      siteMetadata {
        title
        author
        description
        siteUrl
        logo
        twitter
        facebook
        instagram
        linkedin
        youtube
        telephone
      }
    }
  }
`;

export default Seo;
