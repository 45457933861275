export const appCartogram = (url) => {
  return {
    "@context": "https://schema.org",
    "@type": "SoftwareApplication",
    name: "Cartogram - Live Map Wallpapers & Background",
    operatingSystem: "ANDROID",
    applicationCategory: "DesktopEnhancementApplication",
    aggregateRating: {
      "@type": "AggregateRating",
      ratingValue: "4.56",
      ratingCount: "3069",
    },
    offers: {
      "@type": "Offer",
      price: "2.00",
      priceCurrency: "EUR",
    },
    downloadUrl:
      "https://play.google.com/store/apps/details?id=com.round_tower.app.android.wallpaper.cartogram",
    screenshot: {
      "@type": "ImageObject",
      url: "https://firebasestorage.googleapis.com/v0/b/cartogram-6c837.appspot.com/o/0.png?alt=media&token=698548a3-db19-4abb-9415-cd4e36df73af",
    },
  };
};

export const appDyslexia = (url) => {
  return {
    "@context": "https://schema.org",
    "@type": "SoftwareApplication",
    name: "Dyslexia.ai - Tools & Games for Dyslexic Students",
    operatingSystem: "IOS",
    applicationCategory: "EducationalApplication",
    offers: {
      "@type": "Offer",
      price: "0",
      priceCurrency: "EUR",
    },
    aggregateRating: {
      "@type": "AggregateRating",
      ratingValue: "4.3",
      ratingCount: "44",
    },
    downloadUrl: "https://apps.apple.com/ie/app/dyslexia-ai/id1564885095",
    screenshot: {
      "@type": "ImageObject",
      url: "https://storage.googleapis.com/dyslexia-server/screenshots/profile.webp",
    },
  };
};
