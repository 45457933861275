import React, { Component } from "react";
import { Link } from "gatsby";
import logo from "./../../images/logo.png";

export default class NotFound extends Component {
  render() {
    return (
      <section className="bg-background1">
        <Link to="/">
          <img
            className="container w-1/3 mx-auto"
            src={logo}
            alt="Round Tower Logo"
          />
        </Link>
        <h2 className="font-main text-6xl text-center text-white mx-auto">
          404
        </h2>
        <p className="font-main text-center mx-auto text-white/70 pb-20">
          Hmmm.... Something's not quite right...
        </p>
      </section>
    );
  }
}
