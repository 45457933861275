import React from "react";
import { useStaticQuery, graphql } from "gatsby"

import instagramIcon from "./../../images/instagram.svg";
import facebookIcon from "./../../images/facebook.svg";
import linkedinIcon from "./../../images/linkedin.svg";
import twitterIcon from "./../../images/twitter.svg";
import mailIcon from "./../../images/mail.svg";

import { OutboundLink } from "gatsby-plugin-google-gtag";

export default function Social() {
  const { site } = useStaticQuery(query)

  return (
    <ul className="container flex flex-row justify-between p-2 my-4 mx-auto w-2/3 lg:w-1/5">
      <li className="h-10 p-2 rounded-full bg-white/10 hover-animation ">
        <OutboundLink
          href={site.siteMetadata.facebook}
          target="_blank"
          rel="noreferrer"
          className="pointer-events-auto">
          <img src={facebookIcon} alt="Facebook Icon" />
        </OutboundLink>
      </li>
      <li className="h-10 p-2 rounded-full bg-white/10 hover-animation">
        <OutboundLink
           href={site.siteMetadata.instagram}
          target="_blank"
          rel="noreferrer"
          className="z-50 pointer-events-auto">
          <img src={instagramIcon} alt="Instagram Icon" />
        </OutboundLink>
      </li>
      <li className="h-10 p-2 rounded-full bg-white/10 hover-animation">
        <OutboundLink
          href={site.siteMetadata.twitter}
          target="_blank"
          rel="noreferrer"
          className="z-50 pointer-events-auto">
          <img src={twitterIcon} alt="Twitter Icon" />
        </OutboundLink>
      </li>
      <li className="h-10 p-2 rounded-full bg-white/10 hover-animation">
        <OutboundLink
          href={site.siteMetadata.linkedin}
          target="_blank"
          rel="noreferrer"
          className="z-50 pointer-events-auto">
          <img src={linkedinIcon} alt="Linkedin Icon" />
        </OutboundLink>
      </li>
      <li className="h-10 p-2 rounded-full bg-white/10 hover-animation">
        <a
          href={`mailto:${site.siteMetadata.email}?subject=Hello`}
          target="_blank"
          rel="noreferrer"
          className="z-50 pointer-events-auto">
          <img src={mailIcon} alt="Mail Icon" />
        </a>
      </li>
    </ul>
  );
}

const query = graphql`
query SocialQuery {
  site {
    siteMetadata {
      twitter
      facebook
      instagram
      linkedin
      youtube
      email
    }
  }
}
`