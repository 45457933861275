import React, { Fragment } from 'react'
import Seo from '../components/seo/seo'
import NotFound from '../components/404/not_found'
import Footer from '../components/footer/footer'

const NotFoundPage = ({ data }) => (
  <Fragment>
    <NotFound />
    <Footer />
  </Fragment>
)

export default NotFoundPage

export const Head = () => {
  return <Seo title={"Not Found"} />;
};
